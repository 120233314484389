import React from "react";
import { Link } from "react-router-dom";
import { Container, Menu, Button } from "semantic-ui-react";

function Navbar(props) {
  const inverted = props.inverted || false;
  const handleItemClick = () => {
    console.log(`Item clicked`);
  };

  return (
    <Container>
      <Menu
        secondary
        inverted={inverted}
        size="large"
        pointing
        style={{ borderWidth: "0px" }}
      >
        <Menu.Item as={Link} name="home" to="/" />
        <Menu.Item as={Link} name="profile" to="/profile" />
        <Menu.Item as={Link} name="search" to="/search" />
        <Menu.Item position="right">
          <Button inverted={inverted} onClick={handleItemClick}>
            Sign Up
          </Button>
        </Menu.Item>
      </Menu>
    </Container>
  );
}

export default Navbar;
