import React, { useState, useEffect } from "react";
import { withAuth } from "@okta/okta-react";
import { Button, Card, Container, Grid, Image, Input, Message, Segment } from "semantic-ui-react";
import config from "../../config";

import Navbar from "../layout/Navbar";

const INITIAL_FAILURE = {
  failed: false,
  header: "",
  message: ""
};

const INITIAL_PROFILE = {
  _id: undefined,
  name: "",
  phoneNumber: "",
  defaultQuery: "",
  defaultMessage: "",
  pendingMessages: []
};

const baseUrl = config.resourceServer.baseUrl;

function SearchPage(props) {
  const [failure, setFailure] = useState(INITIAL_FAILURE);
  const [profile, setProfile] = useState(INITIAL_PROFILE);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    async function getProfile() {
      if (!profile || !profile.profileId) {
        try {
          const accessToken = await props.auth.getAccessToken();
          const rawResponse = await fetch(`${baseUrl}/profile`, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });

          if (rawResponse.status !== 200) {
            setFailure({
              failed: true,
              header: "Failed to fetch profile",
              message: "Invalid response from server"
            });
            return;
          }

          const data = await rawResponse.json();

          setProfile({ ...data });
          setFailure(INITIAL_FAILURE);
        } catch (error) {
          setFailure({
            failed: true,
            header: "Failed to fetch profile",
            message: error
          });
        }
      }
    }

    getProfile();
  }, [query]);

  const fetchImages = async (search) => {
    if (!search) {
      return;
    }

    try {
      const accessToken = await props.auth.getAccessToken();
      const rawResponse = await fetch(
        `${baseUrl}/images?query=${search}&featured=true`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (rawResponse.status !== 200) {
        setFailure({
          failed: true,
          header: "Failed to fetch images",
          message: "Invalid response from server"
        });
        return;
      }

      const data = await rawResponse.json();

      data.results.forEach(item => {
        item.text = profile.defaultMessage;
        item.added = false;
      });

      setResults(data.results);
      setFailure(INITIAL_FAILURE);
    } catch (error) {
      setFailure({
        failed: true,
        header: "Failed to fetch images",
        message: error
      });
    }
  };

  const addProfileMessage = async (saveItem) => {
    try {
      const accessToken = await props.auth.getAccessToken();
      const rawResponse = await fetch(
        `${baseUrl}/profile/${profile._id}/messages?debug=true`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            imgId: saveItem.id,
            imgUrl: saveItem.imgUrl,
            phoneNumber: profile.phoneNumber,
            text: saveItem.text
          })
        }
      );

      if (rawResponse.status !== 200) {
        setFailure({
          failed: true,
          header: "Failed to save message",
          message: "Invalid response from server"
        });
        return;
      }

      const data = await rawResponse.json();

      const newResults = results.slice();
      newResults.forEach(item => {
        if (item.id === saveItem.id) {
          item.added = true;
        }
      });
  
      setResults(newResults);
      setFailure(INITIAL_FAILURE);
    } catch (error) {
      setFailure({
        failed: true,
        header: "Failed to save message",
        message: error
      });
    }
  };

  const handleInputChange = event => {
    const { id, name, value } = event.target;

    const newResults = results.slice();
    newResults.forEach(item => {
      if (item.id === id) {
        item[name] = value;
      }
    });

    setResults(newResults);
  };

  return (
    <div>
      <Segment vertical>
        <Navbar />
        <Container className="padding-top-large">
          {failure.failed === true && (
            <Message error header={failure.header} content={failure.message} />
          )}

          <form
            onSubmit={e => {
              e.preventDefault();
              fetchImages(search);
            }}
          >
            <Input
              action='Search'
              onChange={e => setSearch(e.target.value)}
              placeholder='Search...' />
          </form>
        </Container>
        <Container className="padding-top-large">
        <Grid container columns={3}>
          {results.map(item => (
            <Grid.Column key={item.id}>
            <Card>
              <Image
                src={item.imgUrl}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header>
                  {search}
                </Card.Header>
                <Card.Description>
                  {item.description}
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Input
                  action={{
                    color: 'teal',
                    icon: 'add',
                    onClick: () => addProfileMessage(item)
                  }}
                  id={item.id}
                  disabled={item.added}
                  name="text"
                  defaultValue={item.text}
                  onChange={handleInputChange}
                />
              </Card.Content>
            </Card>
            </Grid.Column>
          ))}
          </Grid>
        </Container>
      </Segment>
    </div>
  );
}

export default withAuth(SearchPage);
