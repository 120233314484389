const CLIENT_ID = process.env.CLIENT_ID || '0oa1ye1029ApsPa1m357'
const ISSUER = process.env.ISSUER || 'https://dev-125619.okta.com/oauth2/default'
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || true

export default {
    oidc: {
      clientId: CLIENT_ID,
      issuer: ISSUER,
      redirectUri: 'https://thinking-of-you.onrender.com/implicit/callback',
      scopes: ['openid', 'profile', 'email'],
      pkce: true,
      disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    },
    resourceServer: {
      baseUrl: 'https://ee2ed86d.us-south.apigw.appdomain.cloud/api',
    },
  }