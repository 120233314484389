import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Security, SecureRoute, ImplicitCallback } from "@okta/okta-react";
import config from "./config";

import Home from "./components/pages/Home";
import Profile from "./components/pages/Profile";
import Search from "./components/pages/Search";

import "./App.css";

function App() {
  return (
    <Router>
      <Security {...config.oidc}>
        <Route path="/" exact={true} component={Home} />
        <Route path="/implicit/callback" component={ImplicitCallback} />
        <SecureRoute path="/profile" exact={true} component={Profile} />
        <SecureRoute path="/search" exact={true} component={Search} />
      </Security>
    </Router>
  );
}

export default App;
