import React from "react";
import { Container, Header, Label, Segment } from "semantic-ui-react";
import Navbar from "../layout/Navbar";

function Home() {
  return (
    <div>
      <Segment className="vertical masthead center aligned">
        <Navbar inverted="true" />
        <Container text>
          <Header inverted as='h1'>
            Thinking of You
          </Header>
          <Header inverted as='h3'>
            Appreciation . Inspiration . Gratitude . Love
          </Header>
        </Container>
      </Segment>
      <Label
        as='a'
        pointing
        size='mini'
        target='_blank'
        href='https://unsplash.com/@john_cameron?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Photo by John Cameron on Unsplash
      </Label>
    </div>
  );
}

export default Home;
