import React, { useState, useEffect } from "react";
import { withAuth } from "@okta/okta-react";
import { Button, Container, Form, Message, Segment } from "semantic-ui-react";
import config from "../../config";

import Navbar from "../layout/Navbar";

function Profile(props) {
  const INITIAL_FAILURE = {
    failed: false,
    header: "",
    message: ""
  };

  const INITIAL_PROFILE = {
    _id: undefined,
    name: "",
    phoneNumber: "",
    defaultQuery: "",
    defaultMessage: "",
    pendingMessages: []
  };

  const [failure, setFailure] = useState(INITIAL_FAILURE);
  const [profile, setProfile] = useState(INITIAL_PROFILE);

  const baseUrl = config.resourceServer.baseUrl;

  useEffect(() => {
    async function getProfile() {
      if (!profile || !profile.profileId) {
        try {
          const accessToken = await props.auth.getAccessToken();
          const rawResponse = await fetch(`${baseUrl}/profile`, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });

          if (rawResponse.status !== 200) {
            setFailure({
              failed: true,
              header: "Failed to fetch profile",
              message: "Invalid response from server"
            });
            return;
          }

          const data = await rawResponse.json();

          setProfile({ ...data });
          setFailure(INITIAL_FAILURE);
        } catch (error) {
          setFailure({
            failed: true,
            header: "Failed to fetch profile",
            message: error
          });
        }
      }
    }
    getProfile();
  }, [props]);

  const handleInputChange = event => {
    const { name, value } = event.target;

    setProfile({ ...profile, [name]: value });
  };

  const saveProfile = async () => {
    try {
      const accessToken = await props.auth.getAccessToken();
      const rawResponse = await fetch(
        `${baseUrl}/profile/${profile._id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(profile)
        }
      );

      if (rawResponse.status !== 200) {
        setFailure({
          failed: true,
          header: "Failed to save profile",
          message: "Invalid response from server"
        });
        return;
      }

      const data = await rawResponse.json();

      setProfile({...profile, _id: data._id, _rev: data._rev});
      setFailure(INITIAL_FAILURE);
    } catch (error) {
      setFailure({
        failed: true,
        header: "Failed to save profile",
        message: error
      });
    }
  };

  return (
    <div>
      <Segment vertical>
        <Navbar />
        <Container className="padding-top-large">
          {failure.failed === true && (
            <Message error header={failure.header} content={failure.message} />
          )}
          <Form
            onSubmit={async event => {
              event.preventDefault();
              await saveProfile();
            }}
          >
            <Form.Field>
              <label>Profile name</label>
              <input
                placeholder="Profile name"
                name="name"
                value={profile.name}
                onChange={handleInputChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Phone number</label>
              <input
                placeholder="Phone number"
                name="phoneNumber"
                value={profile.phoneNumber}
                onChange={handleInputChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Default query</label>
              <input
                placeholder="Default query"
                name="defaultQuery"
                value={profile.defaultQuery}
                onChange={handleInputChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Default message</label>
              <input
                placeholder="Default message"
                name="defaultMessage"
                value={profile.defaultMessage}
                onChange={handleInputChange}
              />
            </Form.Field>
            <Button primary type="submit">Submit</Button>
          </Form>
        </Container>
      </Segment>
    </div>
  );
}

export default withAuth(Profile);
